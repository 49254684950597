import ApiService from "./Axios";

const BASE_URL = "/user";

const getLoggedinProfile = () => {
    return ApiService.requestWithoutHandler({
        method: "get",
        url: BASE_URL + "/profile",
        data: {},
    })
        .then((response) => {
            return response.data;
        })
        .catch((data) => {
            return null;
        });
};

//     return ApiService.request({
//         method: "get",
//         url: BASE_URL + "/profile",
//         headers: {
//             Authorization: `Bearer ${token}`,
//         },
//         data: {},
//     })
//         .then((response) => {
//             return response.data;
//         })
//         .catch((error) => {
//             console.error("Error fetching user profile:", error);
//             throw error; // Re-throw the error to handle it where the function is called
//         });
// };

const fetchData = (url) => {
    return ApiService.request({
        method: "get",
        url: BASE_URL + url,
        data: {},
    });
};

const postData = (url, data) => {
    return ApiService.request({
        method: "post",
        url: BASE_URL + url,
        data: data,
    });
};

const checkToken = (data) => {
    return ApiService.request({
        method: "post",
        url: BASE_URL + "/check-reset-token",
        data: data,
    });
};

const unBlockUser = (userId, reason) => {
    return ApiService.request({
        method: "post",
        url: BASE_URL + "/user-management/unblock-user",
        data: {
            userId: userId,
            reason: reason,
        },
    });
};

const blockUser = (userId, reason) => {
    return ApiService.request({
        method: "post",
        url: BASE_URL + "/user-management/block-user",
        data: {
            userId: userId,
            reason: reason,
        },
    });
};

const saveWishwaTalks = (data) => {
    return ApiService.request({
        method: "post",
        url: BASE_URL + "/operations/save-wishwa-talks",
        data: data,
    });
};

const resetPassword = (data) => {
    return ApiService.request({
        method: "post",
        url: BASE_URL + "/reset-account-password",
        data: data,
    });
};

const getMyProfile = () => {
    return ApiService.request({
        method: "get",
        url: BASE_URL + "/profile",
        data: {},
    });
};
const getProfileData = () => {
    return ApiService.request({
        method: "get",
        url: BASE_URL + "/profile/get-profile-info",
        data: {},
    });
};
const saveProfile = (data) => {
    return ApiService.request({
        method: "post",
        url: BASE_URL + "/profile/post-profile-info",
        data: data,
    });
};
const savePassword = (data) => {
    return ApiService.request({
        method: "post",
        url: BASE_URL + "/profile/post-change-password",
        data: data,
    });
};

const verifyToken = (token) => {
    return ApiService.request({
        method: "post",
        url: BASE_URL + "/verify-user-email",
        data: {
            token: token,
        },
    });
};
const getProfile = () => {
    return ApiService.request({
        method: "get",
        url: BASE_URL + "/get-org",
        data: {},
    });
};

const SaveOrgPrimaryContact = (data) => {
    return ApiService.request({
        method: "post",
        url: BASE_URL + "/signup-org/save-primary-contact",
        data: data,
    });
};

const saveOrgMainData = (data) => {
    return ApiService.request({
        method: "post",
        url: BASE_URL + "/profile/org/save-org-main-contact",
        data: data,
    });
};

const SaveLegalInfo = (data) => {
    return ApiService.request({
        method: "post",
        url: BASE_URL + "/signup-org/save-legal-info",
        data: data,
    });
};

const SavePrimaryDoc = (data) => {
    return ApiService.request({
        method: "post",
        url: BASE_URL + "/signup-org/save-primary-doc",
        data: data,
    });
};

const SaveOrgProfile = (data) => {
    return ApiService.request({
        method: "post",
        url: BASE_URL + "/signup-org/save-org-profile",
        data: data,
    });
};
const getOrgOTP = (data) => {
    return ApiService.request({
        method: "post",
        url: BASE_URL + "/signup-org/otp",
        data: data,
    });
};
const verifyOrgOTP = (data) => {
    return ApiService.request({
        method: "post",
        url: BASE_URL + "/signup-org/otp-verify",
        data: data,
    });
};

const UploadOrgMediaFile = (file, orgid, isPrivate = 1, id = null) => {
    var formData = new FormData();
    formData.append("file", file);
    formData.append("orgId", orgid);
    formData.append("protected", isPrivate);

    return ApiService.request({
        method: "post",
        url: BASE_URL + "/signup-org/upload-file",
        data: formData,
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};
const UploadOrgPrimaryDoc = (file, orgid, isPrivate = 0, id = null) => {
    var formData = new FormData();
    formData.append("file", file);
    formData.append("orgId", orgid);
    formData.append("docType", "primary");

    return ApiService.request({
        method: "post",
        url: BASE_URL + "/signup-org/upload-file",
        data: formData,
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};

const saveProfileImage = (data) => {
    return ApiService.request({
        method: "post",
        url: BASE_URL + "/profile/upload-profile-info",
        data: data,
    });
};

const createModifyUser = (data) => {
    return ApiService.request({
        method: "post",
        url: BASE_URL + "/user-management/create-modify-user",
        data: data,
    });
};

const findUserList = (data) => {
    return ApiService.request({
        method: "post",
        url: BASE_URL + "/user-management/find-user-list",
        data: data,
    });
};

const getUserInfo = (data) => {
    return ApiService.request({
        method: "post",
        url: BASE_URL + "/user-management/get-user-info",
        data: data,
    });
};

const createRole = (data) => {
    return ApiService.request({
        method: "post",
        url: BASE_URL + "/user-management/create-role-profile",
        data: data,
    });
};

const editRole = (data) => {
    return ApiService.request({
        method: "post",
        url: BASE_URL + "/user-management/edit-role-profile",
        data: data,
    });
};

const updateRolePermissions = (data) => {
    return ApiService.request({
        method: "post",
        url: BASE_URL + "/user-management/permissions/update-role-permissions",
        data: data,
    });
};

const addRoleToUser = (data) => {
    return ApiService.request({
        method: "post",
        url: BASE_URL + "/user-management/add-role-to-user",
        data: data,
    });
};

const editRoleOfUser = (data) => {
    return ApiService.request({
        method: "post",
        url: BASE_URL + "/user-management/edit-role-of-user",
        data: data,
    });
};

const deactivateUser = (data) => {
    return ApiService.request({
        method: "post",
        url: BASE_URL + "/user-management/deactivate-user",
        data: data,
    });
};

const savePrimaryData = (data) => {
    return ApiService.request({
        method: "post",
        url: BASE_URL + "/profile/org/save-primary-data",
        data: data,
    });
};

const saveCompanyData = (data) => {
    return ApiService.request({
        method: "post",
        url: BASE_URL + "/profile/org/save-company-data",
        data: data,
    });
};

const uploadOrgLogo = (data) => {
    return ApiService.request({
        method: "post",
        url: BASE_URL + "/profile/org/upload-org-logo",
        data: data,
    });
};
const updateOrgInfo = (data) => {
    return ApiService.request({
        method: "post",
        url: BASE_URL + "/reviewer/update-org-info",
        data: data,
    });
};

const updateOrgMedia = (data) => {
    return ApiService.request({
        method: "post",
        url: BASE_URL + "/reviewer/update-org-media",
        data: data,
    });
};
const searchLocation = (data) => {
    return ApiService.request({
        method: "get",
        url: BASE_URL + `/locations/search?searchText=${data}`,
    });
};

const getStatesList = () => {};

const getStaticRoles = () => {};

const patchData = (url, data) => {
    return ApiService.request({
        method: "PATCH",
        url: BASE_URL + url,
        data: data,
    });
};

const UserService = {
    SaveLegalInfo: SaveLegalInfo,
    SaveOrgProfile: SaveOrgProfile,
    SaveOrgPrimaryContact: SaveOrgPrimaryContact,
    UploadOrgMediaFile: UploadOrgMediaFile,
    getProfile: getProfile,
    saveProfile: saveProfile,
    getProfileData: getProfileData,
    getMyProfile: getMyProfile,
    getLoggedinProfile: getLoggedinProfile,
    verifyToken: verifyToken,
    fetchData: fetchData,
    saveProfileImage: saveProfileImage,
    createModifyUser: createModifyUser,
    findUserList: findUserList,
    getUserInfo: getUserInfo,
    createRole: createRole,
    editRole: editRole,
    updateRolePermissions: updateRolePermissions,
    addRoleToUser: addRoleToUser,
    editRoleOfUser: editRoleOfUser,
    deactivateUser: deactivateUser,
    savePassword: savePassword,
    savePrimaryData: savePrimaryData,
    saveCompanyData: saveCompanyData,
    uploadOrgLogo: uploadOrgLogo,
    checkToken: checkToken,
    resetPassword: resetPassword,
    saveWishwaTalks: saveWishwaTalks,
    updateOrgInfo: updateOrgInfo,
    updateOrgMedia: updateOrgMedia,
    getOrgOTP: getOrgOTP,
    verifyOrgOTP: verifyOrgOTP,
    UploadOrgPrimaryDoc: UploadOrgPrimaryDoc,
    searchLocation: searchLocation,
    saveOrgMainData: saveOrgMainData,
    unBlockUser,
    blockUser,
    postData,
    getStatesList,
    getStaticRoles,
    SavePrimaryDoc,
    patchData,
};

export { getLoggedinProfile, postData, fetchData };

export default UserService;
